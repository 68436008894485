import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: UserSettingData = null;

export default handleActions<UserSettingData, any>({
  [Actions.CHANGE_RULE_TYPE_VISIBILITY]: (state, action) => {
    var payload = action.payload as RuleTypeVisibility;

    return {
      ...state,
      operatorReviewFilters: {
        ...state.operatorReviewFilters,
        ...payload
      }
    } as UserSettingData;
  },
  [Actions.DGD_RESOURCES_EDIT]: (state, action) => {
    var payload = action.payload as RuleTypeVisibility;
    return {
      ...state,
      ...payload
    } as UserSettingData
  },
  [Actions.SUBMIT_RESOURCE]: (state, action) => {
    var payload = action.payload as RuleTypeVisibility;

    return {
      ...state,
      dgdResourceLoadingStatus: payload
    } as UserSettingData
  },
  [Actions.SWITCH_CHECKLIST_VIEW]: (state, action) => {
    var payload = action.payload as ChecklistView;

    return {
      ...state,
      checklistView: payload
    }
  }
}, initialState);
