import { combineReducers, Reducer, AnyAction } from 'redux';
import userSelections from './userSelections';
import supportingData from './supportingdata';
import applicationState from './applicationstate';
import acceptanceCheckState from './acceptanceCheckState';
import acceptanceCheckReport from './acceptanceCheckReport';
import autoCheckResponse from './auto-check-response';
import dgdData from './dgddata';
import userHints from './userhints';
import userNotes from './usernotes';
import questionToValidationLinks from './questiontovalidationlinks';
import apiUrls from './apiurls';
import dgdViewerStatus from './dgdviewerstatus';
import dgrReferenceStatus from './dgrreferencestatus';
import rightSidebar from './rightSidebar';
import userSettingData from './userSettingData';
import dgdResources from './dgd-resources';
import { Actions } from 'checkspa/constants';
import { acceptanceCheckSyncCompletedReducer } from './acceptance-check-sync-completed';
import { handleActions } from 'redux-actions';

export interface RootState {
  id: string;
  checkBaseUrl: string;
  // Acceptance Check & DGD
  acceptanceCheckState: AcceptanceCheckState;
  dgdData: DgdData;
  userSelections: UserSelections;
  userHints: UserHints;
  userNotes: UserNotes;
  dgdResources: DgdResources;
  autoCheckResponse: AutoCheckResponse;
  acceptanceCheckReport: AcceptanceCheckReport;

  // User permissions & Settings
  applicationState: ApplicationState;
  userSettingData: UserSettingData;

  // SPA interaction state
  dgdViewerStatus: DgdViewerStatus;
  dgrReferenceStatus: DgrReferenceStatus;
  rightSidebar: RightSidebarState;

  // Supporting data
  supportingData: SupportingData;
  questionToValidationLinks: QuestionToValidationLink[];
  apiUrls: ApiUrls;
  dgSyncMap: DangerousGoodAsyncMap;
  features: FeatureFlags;
}


const initialState = null;

const noOpReducer = handleActions<string | any>({

}, initialState);

const combinedReducers = combineReducers<RootState>({
  id: noOpReducer,
  checkBaseUrl: noOpReducer,
  // Acceptance Check & DGD
  acceptanceCheckState,
  dgdData,
  userSelections,
  userHints,
  userNotes,
  dgdResources,
  autoCheckResponse: autoCheckResponse,
  acceptanceCheckReport,

  // User permissions & Settings
  applicationState,
  userSettingData,

  // SPA interaction state
  dgdViewerStatus,
  dgrReferenceStatus,
  rightSidebar,

  // Supporting data
  supportingData,
  apiUrls,
  questionToValidationLinks,
  dgSyncMap: (state, initialState) => state || initialState,
  features: (state, initialState) => state || initialState
});


export default (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case Actions.AC_SYNC_COMPLETED:
      return acceptanceCheckSyncCompletedReducer(state, action as any);
    default:
      return combinedReducers(state, action);
  }
}
