import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Check } from 'checkspa/ui/routes/Check';
import { Landing } from 'checkspa/ui/routes/Landing';
import { LoadableComponent, LoadingMessage } from 'library/loader';
import { UrlTemplates } from 'checkspa/constants';
import { useSmartVerification } from './hooks/useSmartVerification';
import  {lazy, Suspense} from 'react';

const DocumentationAreaAsync = LoadableComponent({
    loader: () => import(/* webpackChunkName: "DocumentationCheck" */'checkspa/ui/routes/DocumentationCheck').then(mod => mod.DocumentationArea)
});

const PackagingCheckAsync = LoadableComponent({
    loader: () => import(/* webpackChunkName: "PackagingCheck" */'checkspa/ui/routes/PackagingCheck').then(mod => mod.PackagingCheck)
});

const VerificationSv = lazy(() => import(/* webpackChunkName: "VerificationSv" */'checkspa/ui/routes/VerificationSv')
  .then(({ Verification }) => ({ default: Verification })));
const Verification = lazy(() => import(/* webpackChunkName: "Verification" */'checkspa/ui/routes/Verification')
  .then(({ Verification }) => ({ default: Verification })));

function VerificationAsync() {
  var smartVerification = useSmartVerification();

  return (
    <Suspense fallback={<LoadingMessage />}>
      { smartVerification ? <VerificationSv /> : <Verification /> }
    </Suspense>
  );
}

const XsdgScreenAsync = LoadableComponent({
    loader: () => import(/* webpackChunkName: "Xsdg" */'checkspa/ui/routes/XsdgDgd').then(mod => mod.XsdgDgd)
});

const AddRowScreenSv = lazy(() => import(/* webpackChunkName: "VerificationSv" */'checkspa/ui/routes/AddRowScreenSv')
  .then(({ AddRowScreen }) => ({ default: AddRowScreen })));
const AddRowScreen = lazy(() => import(/* webpackChunkName: "Verification" */'checkspa/ui/routes/AddRowScreen')
  .then(({ AddRowScreen }) => ({ default: AddRowScreen })));

function AddRowScreenAsync() {
  var smartVerification = useSmartVerification();

  return (
    <Suspense fallback={<LoadingMessage />}>
      { smartVerification ? <AddRowScreenSv /> : <AddRowScreen /> }
    </Suspense>
  );
}

const SignOffScreenAsync = LoadableComponent({
    loader: () => import(/* webpackChunkName: "SignOff" */'checkspa/ui/routes/signoff').then(mod => mod.SignOffScreen)
});

const ReportScreenAsync = LoadableComponent({
    loader: () => import(/* webpackChunkName: "SignOff" */'checkspa/ui/routes/report').then(mod => mod.ReportScreen)
});


declare namespace App {
    export interface Props extends React.Props<any> {

    }

    export interface State {
        /* empty */
    }
}


export class AppRoot extends React.Component<App.Props, App.State> {

    render() {
        return (
            <Check>
                <Switch>
                <Route path="/" exact component={ Landing } />
                { /* START redirects for old URLs */}
                <Redirect from="/overview" to="/verification" exact />
                <Redirect from="/identification" to="/documentation" exact />
                <Route
                    exact
                    path="/identification/:id?/:section?"
                    render={({ match }) => (
                        match.params.section ?
                        <Redirect to={`/documentation/${match.params.id}/${match.params.section}`} />
                        :
                        <Redirect to={`/documentation/${match.params.id}`} />
                    )}
                />
                { /* END redirects for old URLs */}
                <Route path="/documentation/:id?/:section?" component={DocumentationAreaAsync} />
                <Route path="/packaging/:id?/:section?" component={PackagingCheckAsync} />
                <Route path="/signoff" component={ SignOffScreenAsync } />
                <Route path={ UrlTemplates.reportUrl } component={ ReportScreenAsync } />
                <Route path="/dgd" exact component={XsdgScreenAsync} />
                <Redirect path="/verification" to="/verify" exact />
                <Route path="/verify" component={VerificationAsync} />
                <Route path="/editor/add" component={AddRowScreenAsync} />
                </Switch>
            </Check>
        );
    }
}

export const App = hot(AppRoot);
